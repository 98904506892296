import React from 'react'
import { compose } from 'recompose'
import SEO from '../components/seo'
import UserProfile from '../components/UserProfile'
import { AuthUserContext, withAuthorization } from '../components/Session'

const ProfilePage = () => {
	
	return (
		<>
			<SEO title="Profile" />
			<AuthUserContext.Consumer>
				{authUser => (
					<div>
						<UserProfile path='/dashboard/profile' authUser={authUser} />
					</div>
				)}
			</AuthUserContext.Consumer>
		</>
	)
}

const condition = authUser => !!authUser

export default compose(
	withAuthorization(condition),
)(ProfilePage)
