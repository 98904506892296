import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Pane, TextInputField, Combobox, Checkbox, toaster } from "evergreen-ui"
import { Container } from "./primitives/Container"
import { ActionButton } from "./primitives/ActionButton"
import PhotoUploadDialog from "./primitives/PhotoUploadDialog"
import { Avatar } from "./primitives/Avatar"
import { countries } from "../helpers/countries"
import { timezones } from "../helpers/timezones"
import { industries } from "../helpers/industries"
import { withFirebase } from "./Firebase"

const avatar = require("../assets/images/avatar.gif")
const lockCheck = require("../assets/images/svgs/lockCheck.svg")
import "../assets/css/user-profile.css"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { navigate } from "gatsby"
import DashboardLayout from "../Views/Dashboard"
import { FileUploadWithURL } from "../helpers/FileUploadWithURL"


const Wrapper = styled.div`
	${Container} {
	 flex-direction: column;
	}
  
 input{
  margin-bottom: 20px !important;
 }

	/* Medium devices (tablets, 768px and up) */
  @media (min-width: 988px) { 
		${Container} {
			flex-direction: row;
		}
	}
`

const PageTitle = styled.h2`
margin-bottom: 0;
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 35px;
line-height: 123.3%;
text-align: center;
color: #362165;
`


const SmallSectionTitle = styled.h5`
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 123.3%;
text-transform: uppercase;
letter-spacing: 0.2em;
color: #232ED1;
`

const ResetButton = styled.a`
	font-weight: normal;
  text-transform: uppercase;
  color: #F06878 !important;
	margin-bottom: .5rem;
	cursor: pointer;
	font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  line-height: 172.96%;
	img {
		margin-bottom: 0;
		margin-right: 10px;
		vertical-align: middle;
	}
`

const BasicSection = styled.div`
  display: flex;
  flex-direction: column;
	width: 80%;
	margin-top:25px;

  input {
    padding: 1.3rem 1rem;
    font-size: 80%;
    color: #362165;
  }

  input + button {
    height: 2.6rem;
  }
`

const InputTitle = styled.h3`
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 172.96%;
color: #362165;
`

const ButtonText = styled.h3`
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #6564DB;
`


const CompanySection = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  input {
    padding: 1.3rem 1rem;
    font-size: 80%;
    color: #362165;
  }

  input + button {
    height: 2.6rem;
  }
`

const SmallMuted = styled.div`
    font-size: 70%;
    color: #bfbfbf;
    margin-bottom: 1.5rem;
`


const UserProfile = (props) => {

  const { authUser, firebase } = props
  const [accountInfo, setAccountInfo] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [profileUrl, setProfileUrl] = useState(null)
  const [isUpdating, setisUpdating] = useState(false)

  useEffect(() => {
    if (firebase) {
      firebase.db.collection("accounts")
        .doc(authUser.uid)
        .get()
        .then(doc => {
          const data = doc.data()
          setAccountInfo(data)
        })
    }
  }, [firebase])

  const updateProfile = () => {

    setisUpdating(true)

    if (profileUrl) {
      processSubmit(profileUrl)
    } else {

      const data = {
        companyName: accountInfo.companyName,
        country: accountInfo.country,
        industry: accountInfo.industry,
        timezone: accountInfo.timezone,
        canReceivePromoEmails: accountInfo.canReceivePromoEmails,
      }

      saveData(data)
    }
  }

  const processSubmit = (baseImg) => {

    let Data = FileUploadWithURL({ img: baseImg, path: `images/${authUser.uid}/profile.jpg`, firebase: firebase, name: accountInfo.name });

    Data.then(url => {

      const data = {
        companyName: accountInfo.companyName,
        country: accountInfo.country,
        industry: accountInfo.industry,
        timezone: accountInfo.timezone,
        canReceivePromoEmails: accountInfo.canReceivePromoEmails,
        profileImageUrl: url
      }

      saveData(data);
    }).catch(e => {
      console.log(e);
    })
  }

  const saveData = (data) => {
    firebase.db.collection("accounts")
      .doc(authUser.uid).update(data).then(() => {
        setisUpdating(false)
        toaster.success(
          "Your profile has been successfully updated.",
        )
      }).catch((error) => {
        console.log(error)
        setisUpdating(false)
        toaster.danger(
          "Something went wrong trying to update your profile. Please try again later.",
        )
      })
  }

  const resetPassword = () => {
    const emailAddress = accountInfo.email

    firebase.auth.sendPasswordResetEmail(emailAddress).then(() => {
      // Email sent.
      toaster.success(
        "A password reset link has been sent to your email address.",
      )
    }).catch((error) => {
      console.log(error)
      toaster.danger(
        "Something went wrong trying to send your reset link. Please try again later.",
      )
    })
  }

  const onCancel = () => {
    navigate('/dashboard/analytics')
  }

  const uploadImage = (uploadTask) => {
    setAccountInfo({ ...accountInfo, profileImageUrl: uploadTask })
    setProfileUrl(uploadTask)
  }


  return (
    <>
      {
        accountInfo ?
          <DashboardLayout>
            <Wrapper>
              <Container marginTop={16} marginBottom={16} backgroundColor="white" color="#362165">
                <Pane alignSelf="center">
                  <PageTitle>Account Details</PageTitle>
                </Pane>
              </Container>
              <Container backgroundColor="#F7F7F9" color="#362165" minHeight="100vh">
                <Pane display="flex" flexDirection="column" flex={1} className="flex-pane">
                  <SmallSectionTitle>Basic Information</SmallSectionTitle>
                  <Pane display="flex" className="profile-pan" flexDirection="row" alignItems="center">
                    <Avatar marginTop={24} className="avatar-pro" marginRight={30}>
                      <img src={accountInfo.profileImageUrl ? accountInfo.profileImageUrl : avatar} alt="" />
                    </Avatar>

                    <ActionButton backgroundColor="#6564DB" color="#6564DB" marginTop={30} marginBottom={0} border
                      onClick={() => setShowDialog(true)} style={{
                        borderWidth: "2px",
                        borderStyle: "solid",
                        fontSize: "16px",
                      }}>
                      <ButtonText>Change Photo</ButtonText>
                    </ActionButton>
                  </Pane>

                  <BasicSection>
                    <InputTitle>Full Name:</InputTitle>
                    <TextInputField
                      className="input"
                      label=""
                      name="fullname"
                      placeholder={`${accountInfo.firstName} ${accountInfo.lastName}`}
                      value={`${accountInfo.firstName} ${accountInfo.lastName}`}
                      disabled
                    />

                    <InputTitle>Username:</InputTitle>
                    <TextInputField
                      className="input"
                      label=""
                      name="username"
                      placeholder={`${accountInfo.name}`}
                      value={`${accountInfo.name}`}
                      disabled
                    />

                    <InputTitle>Email:</InputTitle>
                    <TextInputField
                      className="input"
                      label=""
                      name="email"
                      placeholder={`${accountInfo.email}`}
                      value={`${accountInfo.email}`}
                      disabled
                    />
                  </BasicSection>
                </Pane>

                <Pane display="flex" flexDirection="column" flex={1} className="flex-pane">
                  <SmallSectionTitle>Company Information</SmallSectionTitle>
                  <SmallMuted><i>*Form fields below are optional</i></SmallMuted>
                  <CompanySection>
                    <InputTitle>Company:</InputTitle>
                    <TextInputField
                      className="input"
                      label=""
                      name="company"
                      placeholder="company name"
                      value={accountInfo.companyName ? `${accountInfo.companyName}` : ""}
                      onChange={e => setAccountInfo({
                        ...accountInfo,
                        companyName: e.target.value,
                      })}
                    />

                    <InputTitle style={{ marginTop: "22px" }}>Industry:</InputTitle>
                    <Combobox
                      openOnFocus
                      initialSelectedItem={accountInfo.industry}
                      items={industries}
                      itemToString={item => item ? item : ""}
                      onChange={selected => setAccountInfo({
                        ...accountInfo,
                        industry: selected,
                      })}
                      placeholder="select industry"
                      width="100%"
                      marginBottom="24px"
                    />

                    <InputTitle>Country:</InputTitle>
                    <Combobox
                      openOnFocus
                      initialSelectedItem={accountInfo.country}
                      items={
                        countries.map(country => country.name)
                      }
                      itemToString={item => item ? item : ""}
                      onChange={selected => setAccountInfo({
                        ...accountInfo,
                        country: selected,
                      })}
                      placeholder="select country"
                      width="100%"
                      marginBottom="24px"
                    />

                    <InputTitle>Timezone:</InputTitle>
                    <Combobox
                      openOnFocus
                      initialSelectedItem={accountInfo.timezone}
                      items={
                        timezones.map(zone => zone.text)
                      }
                      itemToString={item => item ? item : ""}
                      onChange={selected => setAccountInfo({
                        ...accountInfo,
                        timezone: selected,
                      })}
                      placeholder="select timezone"
                      width="100%"
                      marginBottom="24px"
                    />
                    <Pane className="profile-check">
                      <Checkbox label="Receive product emails"
                        checked={accountInfo.canReceivePromoEmails}
                        onChange={e => setAccountInfo({
                          ...accountInfo,
                          canReceivePromoEmails: e.target.checked,
                        })}
                      />
                    </Pane>

                    <Row style={{ marginTop: "30px" }}>
                      <Col sm={6} xs={12} style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexFlow: "column",
                      }}>
                        <InputTitle style={{ color: "#F06878", cursor: "pointer" }} onClick={onCancel}>Cancel</InputTitle>
                      </Col>
                      <Col sm={6} xs={12} style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexFlow: "column",
                      }}>
                        <ActionButton backgroundColor="#F06878" onClick={updateProfile}>
                          {isUpdating ? 'Updating...' : 'Update'}
                        </ActionButton>
                      </Col>
                    </Row>
                  </CompanySection>
                </Pane>

                <Pane display="flex" flexDirection="column" flex={1} className="flex-pane change-pass">
                  <div className="row">
                    <div className="col-md-12" style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexFlow: "column",
                    }}>
                      <ResetButton onClick={resetPassword}><img src={lockCheck} alt="" width="15px" />
                        Change
                        password</ResetButton>
                    </div>
                  </div>
                </Pane>
              </Container>

              <PhotoUploadDialog
                showDialog={showDialog}
                onClose={() => setShowDialog(false)}
                onUpload={(uploadTask) => uploadImage(uploadTask)}
                roundCorners={true}
                currentPhoto={accountInfo.profileImageUrl ? accountInfo.profileImageUrl : avatar}
                uid={authUser ? authUser.uid : ""} />
              {/* <Footer/> */}
            </Wrapper>
          </DashboardLayout>
          : null
      }
    </>
  )
}

UserProfile.propTypes = {
  authUser: PropTypes.any,
  firebase: PropTypes.any,
}

export default withFirebase(UserProfile)
