export const industries = [ 
	'Amusement & Recreation',
	'Automotive',
	'Bars & Night Life',
	'Beauty',
	'Broadcast Media',
	'Consumer Products',
	'Education',
	'Event Services',
	'Eyewear',
	'Fashion',
	'Finance',
	'Government',
	'Health',
	'Hotels &amp; Hospitality',
	'Information Technology',
	'Jewelry',
	'Manufacturing',
	'Museums &amp; Libraries',
	'Not-for-profit',
	'Photography',
	'Professional Services',
	'Publishing &amp; Print Media',
	'Real Estate',
	'Restaurants &amp; Food Service',
	'Retail',
	'Software',
	'Sports',
	'Telecommunications',
	'Tourism',
	'Transportation',
	'Wholesale'
]